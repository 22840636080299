body{
  overflow-x: hidden
}
.navbar {
  background-color: #1B1F29;
  color: #fff;
  /* padding: 21px 0; */
  height: 80px;
}
.navbar-nav{
  padding: 0 25px;
  margin-left: 35px;
}
.navbar-nav .nav-item a{
  border-bottom: 2px solid #1B1F29;

}
.navbar-toggler i{
  color: #fff;
}
.navbar-nav .nav-item a:hover{
  border-bottom: 2px solid #F74356;
}
.navbar-collapse {
  background-color: #1B1F29;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .navbar-collapse{
      position: absolute;
      top: 79px;
  }
  .navbar-nav{
      padding: 0 0px;
      margin-left: 0px;
  }
  .bar-icon{
      display: none;
  }
}
.nav-list a{
  color: #fff;
}
.navbar-nav .nav-link{
  margin-left: 30px;
}
.logo img{
  height: 40px;
  width: auto;
  margin-left: 30px;
}



body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: var(--color-black);
  background: var(--color-light);
}

a,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.star{
  color: #F74356;
  margin-right: 3px;
}
.under-line {
  height: 5px;
  width: 79px;
  background: #D7282F;
  border: none;
  border-radius: 5px;
  margin-top: -8px;
}
.container {
  justify-content: center;
  align-items: center;
  max-width: 80rem;
  min-height: 100vh;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
}

.ion-logo-apple {
  font-size: 1.65rem;
  line-height: inherit;
  margin-right: 0.5rem;
  color: var(--color-black);
}
.ion-logo-google {
  font-size: 1.65rem;
  line-height: inherit;
  margin-right: 0.5rem;
  color: var(--color-red);
}
.ion-logo-facebook {
  font-size: 1.65rem;
  line-height: inherit;
  margin-right: 0.5rem;
  color: var(--color-blue);
}

.text {
  font-family: inherit;
  line-height: inherit;
  text-transform: unset;
  text-rendering: optimizeLegibility;
}
.text-large {
  /* font-size: 2rem; */
  font-weight: 700;
  color: var(--color-black);
  font-size: 37px;
}
.text-normal {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-black);
}
.text-links {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-blue);
}
.text-links:hover {
  text-decoration: underline;
}
main.main {
  margin-top: 7.5%;
}
@media only screen and (max-width: 768px) {
  main.main {
      margin-top: 20%;
  }
}
.main .wrapper {
  max-width: 40rem;
  width: 100%;
  margin: 2rem auto;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
}

.main .wrapper .form {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}
.main .wrapper .form .input-control {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}
.main .wrapper .form .input-field {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  width: 100%;
  height: 35px;
  /* padding: 0.75rem 1.25rem; */
  padding: 8px 8px;
  border: 1px solid #d0d0d0;
  outline: none;
  border-radius: 5px;
  color: var(--color-black);
  background: var(--color-light);
  text-transform: unset;
  text-rendering: optimizeLegibility;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 10%);
}
.images{
  border: 2px solid #0000FF;
  width: 150px;
  padding: 0 10px 3px 10px;
  margin-bottom: 3px;
}
.images img{
  height: 45px;
  width: auto;
}
.main .wrapper .form .input-submit {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 700;
  line-height: inherit;
  cursor: pointer;
  /* height: auto; */
  /* padding: 0.65rem 1.25rem; */
  padding: 16px 50px;
  background: #F74356;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 2rem;
  /* color: var(--color-white); */
  /* background: var(--color-blue); */
  box-shadow: var(--shadow-medium);
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
  margin: 15% 0 10% 0;
}
.main .wrapper .striped {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.main .wrapper .striped-line {
  flex: auto;
  flex-basis: auto;
  border: none;
  outline: none;
  height: 2px;
  background: var(--color-grayish);
}
.form-check {
  margin-top: 32px;
}
.form-check label {
  font-weight: 600;
}
.main .wrapper .striped-text {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: inherit;
  color: var(--color-black);
  margin: 0 1rem;
}

/*.method{*/
/*    margin-top: 15%;*/
/*}*/
.main .wrapper .method-control {
  margin-bottom: 1rem;
}
.main .wrapper .method-action {
  font-family: inherit;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  outline: none;

  color: #F74356;
}
.main .wrapper .method-action span{
  padding: 5px 20px;
  border: 2px solid #F74356;
  border-radius: 2rem;
  transition: .3s;
}
.main .wrapper .method-action span:hover{
  background: #F74356;
  color: #fff;
}
.main .wrapper .method-action:hover {
  background: var(--color-light);
}
.btn-area{
  margin-top: 8%;
}
.btn-1{
  margin-bottom: 10%;
}
.boxed-btn {
  padding: 18px 50px;
  background: #F74356;
  color: #fff;
  border: 2px solid #F74356;
  font-weight: 700;
  border-radius: 5rem;
  transition: .3s;
  font-size: 22px;
}
.boxed-btn:hover{
  background-color: #B52227;
  color: #fff;
}


.section-bg{
  background-color: #FCFCFC;
  padding: 60px 0;
}
.bottom-area{
  margin-left: 6.4%;
}
.img-1 img{
  height: 70px;
  width: auto;

}
.img-2 img{
  height: 40px;
  width: auto;
  margin-top: 15px;

}

.footer-area{
  background-color: #1B1F29;
  padding: 50px 0px 20px 0;
  border-bottom: 5px solid #D7282F;
}
.footer-area ul li{
  list-style: none;
  margin-top: 5px;
  color: #fff;
  
}
.footer-area ul p{
  color: #333;
  font-size: 9px;
  overflow: hidden;
}
.footer-area ul li a{
  color: #fff;
  border-bottom: 1px solid #B52227;
}
@media only screen and (max-width: 768px) {
  .row{
      padding: 0;
  }
  .main .wrapper{
      max-width: 32rem;
  }
  .btn-area .btn-1{
      margin-bottom: 12%;
  }
}